import xior, { XiorInstance } from 'xior'
import dedupePlugin from 'xior/plugins/dedupe'

let xiorInstance: XiorInstance | null = null

/**
 * Configuration type for the Baserow API client
 */
interface BaserowApiConfig {
  baseURL: string
  headers: {
    Authorization: string
    'Content-Type': string
  }
}

/**
 * Creates or returns an existing Baserow API client instance
 * @param token - JWT token for authentication
 * @throws {Error} If BASEROW_URL environment variable is not set
 * @returns XiorInstance configured for Baserow API
 */
export const getBaserowApi = (token: string): XiorInstance => {
  if (!process.env.BASEROW_URL) {
    throw new Error('BASEROW_URL environment variable is not set')
  }

  if (!xiorInstance) {
    const config: BaserowApiConfig = {
      baseURL: `${process.env.BASEROW_URL}/api`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    }

    xiorInstance = xior.create(config)
    xiorInstance.plugins.use(dedupePlugin())
  } else {
    xiorInstance.defaults.headers.Authorization = `JWT ${token}`
  }

  return xiorInstance
}
