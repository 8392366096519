import { registryAdapter } from 'api/src/common/baserowAdapter/baserowRegistryAdapter'

const getFieldType = (fieldTypeString) => {
  return registryAdapter.get('field', fieldTypeString)
}

const getFilterType = (filterTypeString) => {
  return registryAdapter.get('viewFilter', filterTypeString)
}

const filterRecord = (
  record,
  filterType,
  filterValue,
  filterField,
  fullField,
) => {
  let recordField = null
  for (const property in record.fields) {
    if (record.fields[property]?.id === filterField) {
      recordField = record.fields[property]
      break
    }
  }

  const fieldType = getFieldType(recordField.type)

  const result = filterType.matches(
    recordField.value,
    filterValue,
    fullField,
    fieldType,
  )

  return result
}

const evaluateFilters = (record, filters, filterGroups, tableFieldsData) => {
  let totalFilterCount = 0
  let totalFilterMatchCount = 0

  if (filters.length === 0) {
    totalFilterMatchCount++
  }

  const evaluateGroup = (group) => {
    let groupFilters = filters.filter((filter) => filter.group === group.id)
    let groupMatchCount = 0

    const subgroups = filterGroups.filter(
      (subgroup) => subgroup.parent_group === group.id,
    )

    for (const subgroup of subgroups) {
      const subgroupMatch = evaluateGroup(subgroup)
      if (subgroupMatch) {
        groupMatchCount++
      }
    }

    let filtersMatchCount = 0

    for (let groupFilter of groupFilters) {
      const filterType = getFilterType(groupFilter.type)

      if (filterType) {
        const tableField = tableFieldsData.find(
          (field) => field.id === groupFilter.field,
        )
        const isMatch = filterRecord(
          record,
          filterType,
          groupFilter.value,
          groupFilter.field,
          tableField,
        )
        if (isMatch) {
          filtersMatchCount++
        }
      }
    }

    if (
      (group.filter_type === 'AND' &&
        filtersMatchCount >= groupFilters.length &&
        groupMatchCount >= subgroups.length) ||
      (group.filter_type === 'OR' &&
        (filtersMatchCount > 0 || groupMatchCount > 0))
    ) {
      return true
    }

    return false
  }

  if (filterGroups) {
    for (const filterGroup of filterGroups.filter((fg) => !fg.parent_group)) {
      totalFilterCount++
      const groupMatch = evaluateGroup(filterGroup)
      if (groupMatch) {
        totalFilterMatchCount++
      }
    }
  }

  for (const filter of filters) {
    if (filter.group === null) {
      totalFilterCount++
      const filterType = getFilterType(filter.type)
      if (filterType) {
        const tableField = tableFieldsData.find(
          (field) => field.id === filter.field,
        )
        const isMatch = filterRecord(
          record,
          filterType,
          filter.value,
          filter.field,
          tableField,
        )
        if (isMatch) {
          totalFilterMatchCount++
        }
      }
    }
  }

  return { totalFilterCount, totalFilterMatchCount }
}

export const getRecordDecorationsInView = (
  records,
  viewData,
  tableFieldsData,
) => {
  const decorations = viewData.decorations || []

  for (const record of records) {
    record.decorators = {}
    record.decorators.left_border_color = []

    for (const decoration of decorations) {
      const isEmpty =
        decoration?.value_provider_conf &&
        Object.keys(decoration.value_provider_conf).length === 0
      if (isEmpty) continue

      record.decorators[decoration.type] = []
      if (decoration.value_provider_type === 'single_select_color') {
        const fieldId = decoration.value_provider_conf.field_id
        if (!fieldId) continue
        const colorObjects = []
        // Get the color objects for the field
        for (const field of tableFieldsData) {
          if (field.id === fieldId) {
            for (const colorObject of field.select_options) {
              colorObjects.push(colorObject)
            }
          }
        }
        // Check if the record field value matches any of the color values
        for (const recordField of record.fields) {
          if (recordField.id === fieldId) {
            for (const colorObject of colorObjects) {
              if (recordField.value?.value === colorObject.value) {
                record.decorators[decoration.type].push({
                  color: colorObject.color,
                  id: colorObject.id,
                })
              }
            }
          }
        }
      } else {
        for (const colorObject of decoration.value_provider_conf.colors) {
          const { totalFilterCount, totalFilterMatchCount } = evaluateFilters(
            record,
            colorObject.filters,
            colorObject.filter_groups,
            tableFieldsData,
          )

          // Push to decorators if the filter conditions are met
          if (
            (colorObject.operator === 'AND' &&
              totalFilterMatchCount >= totalFilterCount) ||
            ((colorObject.operator === 'OR' ||
              colorObject.operator === null ||
              !colorObject.operator) &&
              totalFilterMatchCount > 0)
          ) {
            record.decorators[decoration.type].push({
              color: colorObject.color,
              id: colorObject.id,
            })
          }
        }
      }
    }
  }
  return records
}

export const filterRecordsInView = (records, viewData, tableFieldsData) => {
  if (viewData?.filters_disabled || !viewData) {
    return records
  }
  const filteredRecords = []
  const filters = viewData.filters
  const filterOperator = viewData.filter_type

  for (const record of records) {
    const { totalFilterCount, totalFilterMatchCount } = evaluateFilters(
      record,
      filters,
      viewData.filter_groups,
      tableFieldsData,
    )

    // Push to filteredRecords if the filter conditions are met
    if (
      (filterOperator === 'AND' && totalFilterMatchCount >= totalFilterCount) ||
      ((filterOperator === 'OR' ||
        filterOperator === null ||
        !filterOperator) &&
        totalFilterMatchCount > 0)
    ) {
      filteredRecords.push(record)
    }
  }

  return filteredRecords
}
