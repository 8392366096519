import React, {
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import * as IconSet from '@heroicons/react/24/outline'
import {
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  CodeBracketSquareIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
  TrashIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { CircularProgress, Menu, MenuItem, Tooltip } from '@mui/material'
import { captureEvent } from '@sentry/browser'
import {
  AlignLeft,
  Binocular,
  BoxIso,
  Calculator,
  Calendar,
  CheckCircle,
  ClockRotateRight,
  Community,
  Edit,
  EmptyPage,
  EvPlug,
  Fingerprint,
  Hashtag,
  IconoirProvider,
  InfoCircle,
  Link,
  List,
  Lock,
  MagicWand,
  Mail,
  NumberedListLeft,
  PageDown,
  Phone,
  Plus,
  QuestionMark,
  SigmaFunction,
  Star,
  Text,
  User,
} from 'iconoir-react'
import { isNil } from 'ramda'

import { toast } from '@redwoodjs/web/toast'

import CommentsPanel from 'src/components/HubDash/CardExpand/CommentsPanel/CommentsPanel'
import { CellRenderer } from 'src/components/HubDash/CellRenderer'
import { FieldRenderer } from 'src/components/HubDash/FieldRenderer'
import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import {
  useBaserowRecordSocket,
  type CardSocket,
} from 'src/components/HubDash/lib/baserow/useBaserowSocket'
import Button from 'src/components/Library/Button/Button'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import useHubDashStore from 'src/lib/stores/hubDashStore'
import { useAuth } from 'src/Providers'

import {
  createRecord,
  deleteRecord,
  getWorkspacePermissions,
} from '../lib/baserow/baserowApi'
import { BaserowFieldType, BaserowFormulaType } from '../lib/enums'
import type { HubDashCardType } from '../lib/types'

const ErrorPlaceholder = ({ msg }) => {
  return (
    <div className="flex h-full w-full justify-center">
      <div className="flex flex-col items-center gap-2 pt-32">
        <XCircleIcon className="h-48 w-48 text-red-500" />
        <p className="text-xl text-gray-500">{msg}</p>
      </div>
    </div>
  )
}

interface TableFieldsProps {
  field: any // not typed yet
  isStafflink: boolean
  record: any // not typed yet
  fieldIcon: React.JSX.Element // not typed yet
  setLoading: Dispatch<SetStateAction<boolean>>
  baseId: number
  card: HubDashCardType
  editableByPermission: boolean
}

const TableFields = ({
  field,
  isStafflink,
  record,
  fieldIcon,
  setLoading,
  baseId,
  card,
  editableByPermission,
}: TableFieldsProps) => {
  const isFormulaBtnField =
    field.type === BaserowFieldType.FORMULA &&
    field.formula_type === BaserowFormulaType.BUTTON
  return (
    <div className="flex rounded p-2 hover:bg-gray-50">
      <IconoirProvider
        iconProps={{
          color: '#AAAAAA',
          strokeWidth: 2,
          width: '1em',
          height: '1em',
        }}
      >
        <div className="mt-0.5 flex w-48 max-w-48 flex-shrink-0 gap-2 truncate">
          <Tooltip
            disableFocusListener
            title={
              !isStafflink ? null : (
                <div className="flex flex-col gap-1">
                  <p className="text-sm font-bold text-white">RAW VALUE</p>
                  <p className="border-b-2 border-white pb-2 text-sm text-white">
                    {JSON.stringify(
                      record?.fields.find((rField) => rField.id === field.id)
                        ?.value,
                    )}
                  </p>
                  <p className="mt-2 text-sm font-bold text-white">
                    FIELD CONFIG
                  </p>
                  {Object.entries(field).map(([key, value]) => {
                    return (
                      <p key={key} className="text-sm text-white">
                        {key}: {JSON.stringify(value)}
                      </p>
                    )
                  })}
                </div>
              )
            }
          >
            <div className="mt-1">{fieldIcon}</div>
          </Tooltip>
          <Tooltip
            title={field.name}
            disableFocusListener
            enterDelay={500}
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -12],
                    },
                  },
                ],
              },
            }}
          >
            <div className="mr-2 w-full truncate">
              <p className="truncate text-sm text-gray-500">{field.name}</p>
            </div>
          </Tooltip>
          <div>
            <Tooltip
              title={field?.description}
              disableFocusListener
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -4],
                      },
                    },
                  ],
                },
              }}
            >
              <div className="mr-2">{field?.description && <InfoCircle />}</div>
            </Tooltip>
          </div>
        </div>
      </IconoirProvider>
      <Tooltip
        title={
          (field.read_only || !editableByPermission) && !isFormulaBtnField
            ? 'This field is read-only.'
            : ''
        }
        arrow
        placement="top"
      >
        <div className={'w-full'}>
          <CellRenderer
            field={field}
            record={record}
            isName={false}
            className={null}
            setLoading={setLoading}
            baseId={baseId}
            card={card}
            editableByPermission={editableByPermission}
          />
        </div>
      </Tooltip>
    </div>
  )
}

export const getIconForField = (field) => {
  switch (field.type) {
    case 'text':
      return <Text />
    case 'long_text':
      return <AlignLeft />
    case 'link_row':
      return <EvPlug />
    case 'number':
      return <Hashtag />
    case 'rating':
      return <Star />
    case 'boolean':
      return <CheckCircle />
    case 'date':
      return <Calendar />
    case 'last_modified':
      return <Edit />
    case 'last_modified_by':
      return <User />
    case 'created_on':
      return <Plus />
    case 'created_by':
      return <User />
    case 'duration':
      return <ClockRotateRight />
    case 'url':
      return <Link />
    case 'email':
      return <Mail />
    case 'file':
      return <EmptyPage />
    case 'single_select':
      return <PageDown />
    case 'multiple_select':
      return <List />
    case 'phone_number':
      return <Phone />
    case 'formula':
      return <SigmaFunction />
    case 'count':
      return <Calculator />
    case 'rollup':
      return <BoxIso />
    case 'lookup':
      return <Binocular />
    case 'multiple_collaborators':
      return <Community />
    case 'uuid':
      return <Fingerprint />
    case 'autonumber':
      return <NumberedListLeft />
    case 'password':
      return <Lock />
    case 'ai':
      return <MagicWand />
    default:
      return <QuestionMark />
  }
}

interface RecordExpandProps {
  cardSocket: CardSocket
  selectedRecord: {
    id: number
    workspaceId: number
    getWorkspaceUsers: () => void
  }
  clearWrappedRecord: () => void
  setCommentsOpen: Dispatch<SetStateAction<boolean>>
  commentsOpen: boolean
  card: HubDashCardType
}

const RecordExpand: FC<RecordExpandProps> = ({
  cardSocket,
  selectedRecord,
  clearWrappedRecord,
  setCommentsOpen,
  commentsOpen,
  card,
}) => {
  const token = useHubDashStore((state) => state.token)
  const { currentUser } = useAuth()
  const isStafflink = currentUser.userData.email.includes('@stafflink.com.au')
  const [searchValue, setSearchValue] = useState('')
  const [isTitleVisible, setIsTitleVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [workspacePermission, setWorkspacePermission] = useState(null)
  const divRef = useRef(null)
  const recordInCard = cardSocket.records.find(
    (record) => record?.id === selectedRecord?.id,
  )
  const primaryField = cardSocket.table.fields.find((field) => field.primary)

  //options button states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const optionsBtnOpen = Boolean(anchorEl)
  const handleOptionsBtnClose = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }
  const handleOptionsBtnOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleDeleteRecord = async () => {
    const tableId = cardSocket.table.id
    const rowId = recordInCard.id
    try {
      await deleteRecord({ tableId, rowId, token })
      clearWrappedRecord()
      toast.success('Record deleted')
    } catch (error) {
      toast.error('Failed to delete record')
      captureEvent({
        message: 'HubDash: Failed to delete record.',
        level: 'error',
        extra: {
          tableId: tableId,
          recordId: rowId,
          errorCode: error?.response?.data?.error,
        },
      })
    }
  }
  const handleDuplicateRecord = async () => {
    const tableId = cardSocket.table.id
    const toSkipTypes = [
      'last_modified_by',
      'created_by',
      'last_modified',
      'created_on',
      'formula',
      'count',
      'uuid',
      'autonumber',
      'lookup',
    ]
    const recordData = recordInCard.fields.reduce((acc, item) => {
      const formattedKey = `field_${item.id}`
      if (toSkipTypes.includes(item.type) || isNil(item.value)) {
        return acc
      }
      if (item.type === 'single_select') {
        acc[formattedKey] = item.value.id
      } else if (item.type === 'multiple_select' || item.type === 'link_row') {
        acc[formattedKey] = item.value.map((val) => val.id)
      } else if (item.type === 'file') {
        acc[formattedKey] = item.value.map((val) => ({ name: val.name }))
      } else if (item.type === 'multiple_collaborators') {
        acc[formattedKey] = item.value.map((val) => ({ id: val.id }))
      } else {
        acc[formattedKey] = item.value
      }
      return acc
    }, {})
    try {
      //create record
      await createRecord({ token, recordData, tableId })
      toast.success('Record duplicated successfully')
      setAnchorEl(null)
    } catch (error) {
      toast.error('Failed to duplicate record')
      captureEvent({
        message: 'HubDash: Failed to duplicate record.',
        level: 'error',
        extra: {
          tableId: tableId,
          errorCode: error?.response?.data?.error,
        },
      })
    }
  }

  const [showHiddenActive, setShowHiddenActive] = useState(false)
  //show all non-hidden fields for old cards that don't have hidden fields settings (default behavior)
  const allowShowHiddenFields =
    card?.cardSettings?.hiddenFields?.allowShowHiddenFields || false

  const recordSocket = useBaserowRecordSocket({
    tableId: cardSocket.table.id,
    recordId: selectedRecord.id,
    recordData: selectedRecord,
    tableData: cardSocket.table,
    viewData: cardSocket.view,
  })

  const record = recordSocket.record

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsTitleVisible(entry.isIntersecting)
    })
    if (divRef.current) {
      observer.observe(divRef.current)
    }
    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const fetchPermissions = async () => {
      const permissions = await getWorkspacePermissions({
        token,
        workspaceId: selectedRecord.workspaceId,
      })
      setWorkspacePermission(permissions)
    }
    fetchPermissions()
  }, [])

  if (recordSocket && !record) {
    return <ErrorPlaceholder msg="This record has been deleted" />
  }

  if (!cardSocket) return null

  if (workspacePermission?.error) {
    return (
      <ErrorPlaceholder msg="An error occurred fetching Workspace permissions" />
    )
  }

  const nameIcon = getIconForField(primaryField)

  const filteredTableFields = cardSocket.table.fields.filter((field) => {
    return (
      !field.primary &&
      (searchValue === '' ||
        field.name?.toLowerCase().includes(searchValue?.toLowerCase()))
    )
  })

  //hide toggle hidden fields button if there is no hidden fields
  const hasHiddenFields = filteredTableFields.some(
    (field) => field.viewHidden === true,
  )

  return (
    <>
      <div className="flex h-full w-full min-w-0">
        <div
          className="flex min-w-0 flex-1 flex-col"
          data-testid="record-expand"
        >
          <div
            className={`flex min-w-0 items-center justify-between gap-2 p-2 ${!isTitleVisible ? 'border-b border-gray-200' : 'mb-2'} `}
            style={{
              backgroundColor:
                record?.decorators?.background_color?.length > 0
                  ? getHexForColor(
                      record?.decorators?.background_color[0].color,
                    )
                  : '',
            }}
          >
            <div className="flex min-w-[20px] gap-7 py-1">
              <button
                onClick={clearWrappedRecord}
                className="cursor-pointer rounded-lg hover:bg-gray-100"
              >
                <ChevronLeftIcon className="h-6 w-6 text-gray-400" />
              </button>
              {!isTitleVisible && (
                <div className="truncate">
                  <FieldRenderer
                    field={primaryField}
                    record={record}
                    isName={false}
                  />
                </div>
              )}
            </div>
            <div className="flex h-full items-center gap-2">
              {loading && !isTitleVisible && (
                <div className="text-indigo-600">
                  <CircularProgress
                    color="inherit"
                    className="mr-2 mt-1 !h-6 !w-6"
                  />
                </div>
              )}
              {!isTitleVisible && !loading && (
                <div className="flex items-center gap-2 py-1 pr-2">
                  <>
                    {!recordInCard && (
                      <Tooltip
                        title={
                          <div className="flex flex-col">
                            <p className="font-bold">
                              This record is no longer in the card or view.
                            </p>
                          </div>
                        }
                      >
                        <ExclamationCircleIcon className="h-7 w-7 text-orange-500" />
                      </Tooltip>
                    )}
                    {record?.decorators?.left_border_color?.map((color) => {
                      const colorIconName = card?.cardColors.find(
                        (c) => c.colorName === color.color,
                      )

                      const ColorIcon = colorIconName
                        ? IconSet[colorIconName.icon]
                        : null
                      return (
                        <Tooltip
                          title={
                            <div className="flex flex-col">
                              <p className="font-bold">
                                {colorIconName?.name ||
                                  'This color has not been defined.'}
                              </p>
                              <p>{colorIconName?.description}</p>
                            </div>
                          }
                          key={color.id}
                        >
                          <div
                            className="grid h-6 w-6 place-items-center rounded-md border border-white"
                            key={color.id}
                            style={{
                              backgroundColor: getHexForColor(color.color),
                            }}
                          >
                            {ColorIcon && (
                              <ColorIcon className="h-5 w-5 text-white" />
                            )}
                          </div>
                        </Tooltip>
                      )
                    })}
                  </>
                </div>
              )}
              <div className="w-48">
                <SearchField
                  value={searchValue}
                  onChange={(e) => setSearchValue(e)}
                  placeholder="Search fields"
                />
              </div>
              <div>
                <Button
                  fullWidth={false}
                  variant="text"
                  color="secondary"
                  className="min-w-[0px] p-1"
                  onClick={(e) => handleOptionsBtnOpen(e)}
                  aria-label="Notification preferences"
                  aria-expanded={optionsBtnOpen}
                  aria-haspopup="menu"
                >
                  <EllipsisHorizontalIcon className="h-6 w-6" />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={optionsBtnOpen}
                  onClose={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => handleOptionsBtnClose(e)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    role: 'menu',
                    'aria-label': 'Notification preferences',
                  }}
                  onClick={(e) => e.stopPropagation()}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  slotProps={{
                    paper: {
                      sx: { mt: 1 },
                    },
                  }}
                >
                  <MenuItem
                    className="flex gap-2 py-3"
                    onClick={handleDuplicateRecord}
                  >
                    <DocumentDuplicateIcon className="h-5 w-5 text-black" />
                    <p className="w-full text-sm text-black">
                      Duplicate record
                    </p>
                  </MenuItem>
                  <MenuItem
                    className="flex gap-2 py-3 text-red-500"
                    onClick={handleDeleteRecord}
                  >
                    <TrashIcon className="h-5 w-5" />
                    <p className="w-full text-sm">Delete record</p>
                  </MenuItem>
                </Menu>
              </div>

              <Tooltip title={'Open in Bases'}>
                <button
                  onClick={() => {
                    const viewId = cardSocket?.view?.id || ''
                    const url = `/app/baselink/database/${cardSocket.base.id}/table/${cardSocket.table.id}${viewId ? `/${viewId}` : ''}/row/${record?.id || ''}`
                    openWindowWithBlockCheck(url, '_blank')
                  }}
                  className="mr-1 cursor-pointer rounded-lg hover:bg-gray-100"
                >
                  <CodeBracketSquareIcon className="h-6 w-6 text-gray-400" />
                </button>
              </Tooltip>
              <Tooltip title={`${commentsOpen ? 'Close' : 'Open'} Comments`}>
                <button
                  onClick={() => setCommentsOpen(!commentsOpen)}
                  className="cursor-pointer rounded-lg hover:bg-gray-100"
                >
                  <ChatBubbleLeftRightIcon className="h-6 w-6 text-gray-400" />
                </button>
              </Tooltip>
              <button
                onClick={clearWrappedRecord}
                className="cursor-pointer rounded-lg hover:bg-gray-100"
              >
                <XMarkIcon className="h-8 w-8 text-gray-400" />
              </button>
            </div>
          </div>
          <div className="hide-scrollbar flex w-full flex-col overflow-scroll px-12 pb-96">
            <div className="flex w-full flex-col" ref={divRef}>
              <div className="mb-2 flex h-8 w-full justify-between pl-2">
                <IconoirProvider
                  iconProps={{
                    color: '#AAAAAA',
                    strokeWidth: 2,
                    width: '1em',
                    height: '1em',
                  }}
                >
                  <div className="flex items-end gap-1">
                    <div className="mb-0.5">{nameIcon}</div>
                    <p className="text-sm text-gray-500">{primaryField.name}</p>
                  </div>
                </IconoirProvider>
                {loading && (
                  <div className="text-indigo-600">
                    <CircularProgress color="inherit" className="!h-8 !w-8" />
                  </div>
                )}
                {!loading && (
                  <div className="flex items-center gap-2">
                    <>
                      {!recordInCard && (
                        <Tooltip
                          title={
                            <div className="flex flex-col">
                              <p className="font-bold">
                                This record is no longer in the card or view.
                              </p>
                            </div>
                          }
                        >
                          <ExclamationCircleIcon className="h-9 w-9 text-orange-500" />
                        </Tooltip>
                      )}
                      {record?.decorators?.left_border_color?.map((color) => {
                        const colorIconName = card?.cardColors.find(
                          (c) => c.colorName === color.color,
                        )
                        const ColorIcon = colorIconName
                          ? IconSet[colorIconName.icon]
                          : null
                        return (
                          <Tooltip
                            title={
                              <div className="flex flex-col">
                                <p className="font-bold">
                                  {colorIconName?.name ||
                                    'This color has not been defined.'}
                                </p>
                                <p>{colorIconName?.description}</p>
                              </div>
                            }
                            key={color.id}
                          >
                            <div
                              className="h-8 w-8 rounded-md"
                              style={{
                                backgroundColor: getHexForColor(color.color),
                              }}
                            >
                              {ColorIcon && (
                                <ColorIcon className="h-8 w-8 text-white" />
                              )}
                            </div>
                          </Tooltip>
                        )
                      })}
                    </>
                  </div>
                )}
              </div>
              <Tooltip
                title={
                  primaryField.read_only ||
                  !workspacePermission?.rowUpdatePermission
                    ? 'This field is read-only.'
                    : ''
                }
                arrow
                placement="top"
              >
                <div>
                  <CellRenderer
                    editableByPermission={
                      workspacePermission?.rowUpdatePermission
                    }
                    field={primaryField}
                    record={record}
                    className={null}
                    isName={true}
                    setLoading={setLoading}
                    baseId={cardSocket.base.id}
                    card={card}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="mt-4 flex flex-col gap-2 border-t border-gray-300 pt-4">
              {searchValue && filteredTableFields?.length === 0 && (
                <p className="w-full py-8 text-center text-lg text-gray-500">
                  No fields match your search term.
                </p>
              )}

              {filteredTableFields.map((field) => {
                if (!field.viewHidden) {
                  const fieldIcon = getIconForField(field)
                  return (
                    <TableFields
                      key={field.id}
                      field={field}
                      isStafflink={isStafflink}
                      record={record}
                      fieldIcon={fieldIcon}
                      setLoading={setLoading}
                      baseId={cardSocket.base.id}
                      card={card}
                      editableByPermission={
                        workspacePermission?.rowUpdatePermission
                      }
                    />
                  )
                } else {
                  return null
                }
              })}
              {allowShowHiddenFields && hasHiddenFields && (
                <>
                  <div className="relative my-5 flex items-center justify-between gap-3">
                    <div
                      className="flex-1 bg-gray-300"
                      style={{ height: '1px' }}
                    ></div>
                    <button
                      onClick={() => {
                        setShowHiddenActive((prev) => !prev)
                      }}
                      className="flex cursor-pointer items-center gap-3 border border-gray-300 p-2 text-sm hover:bg-gray-100"
                    >
                      {showHiddenActive ? 'Hide' : 'Show'} hidden fields
                      {showHiddenActive ? (
                        <ChevronUpIcon className="h-3 w-3 text-black" />
                      ) : (
                        <ChevronDownIcon className="h-3 w-3 text-black" />
                      )}
                    </button>
                    <div
                      className="flex-1 bg-gray-300"
                      style={{ height: '1px' }}
                    ></div>
                  </div>
                  {filteredTableFields.map((field) => {
                    if (field.viewHidden && showHiddenActive) {
                      const fieldIcon = getIconForField(field)
                      return (
                        <TableFields
                          key={field.id}
                          field={field}
                          isStafflink={isStafflink}
                          record={record}
                          fieldIcon={fieldIcon}
                          setLoading={setLoading}
                          baseId={cardSocket.base.id}
                          card={card}
                          editableByPermission={
                            workspacePermission?.rowUpdatePermission
                          }
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              )}
            </div>
          </div>
        </div>
        {commentsOpen && (
          <div className="h-full min-w-[300px] max-w-[500px] flex-1 border-l border-gray-200">
            <CommentsPanel
              selectedRecord={{
                ...record,
                activityUpdates: recordSocket.activityUpdates,
                workspaceId: selectedRecord.workspaceId,
                getWorkspaceUsers: selectedRecord.getWorkspaceUsers,
              }}
              workspacePermission={workspacePermission}
              table={cardSocket.table}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default RecordExpand
