/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import type { FC } from 'react'

import { CheckIcon } from '@heroicons/react/24/outline'
import { Rating, Tooltip } from '@mui/material'
import { formatDurationValue } from 'api/src/common/baserowImport/baserow/modules/database/utils/duration'
import dayjs from 'dayjs'
import { isNil } from 'ramda'
import { NameInitialsAvatar } from 'react-name-initials-avatar'

import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import { type CardSocketTableField } from 'src/components/HubDash/lib/baserow/useBaserowSocket'
import Button from 'src/components/Library/Button/Button'
import { openWindowWithBlockCheck } from 'src/lib/helpers'

import { BaserowFieldType, BaserowFormulaType } from './lib/enums'
import numberInputFormatter from './lib/numberInputFormatter'

interface ColoredChipProps {
  data: {
    value: string
    color: string
  }
  isName: boolean
}
export const ColoredChip: FC<ColoredChipProps> = ({ data, isName }) => {
  if (!data) return null
  return (
    <div
      className="w-fit rounded-full px-2"
      style={{
        backgroundColor: getHexForColor(data.color),
      }}
    >
      <p className={`${isName ? 'text-2xl' : ''} text-sm`}>{data.value}</p>
    </div>
  )
}

interface DateValueProps {
  value: any
  includeTime: boolean
  timeFormat: string
  timeZone: null | string
  showTimeZone: boolean
}
const DateValue: FC<DateValueProps> = ({
  value,
  includeTime = false,
  timeFormat = '24',
  timeZone,
  showTimeZone,
}) => {
  if (includeTime && value) {
    let formattedTime = ''
    if (timeZone) {
      formattedTime = dayjs(value)
        .tz(timeZone)
        .format(`DD/MM/YYYY ${timeFormat === '24' ? 'HH:mm' : 'hh:mma'}`)
    } else {
      formattedTime = dayjs(value).format(
        `DD/MM/YYYY ${timeFormat === '24' ? 'HH:mm' : 'hh:mma'}`,
      )
    }
    return (
      <p>
        {formattedTime}
        {showTimeZone && ` (${timeZone ? timeZone : dayjs.tz.guess()})`}
      </p>
    )
  } else if (value) {
    return <p className="truncate">{dayjs(value).format('DD/MM/YYYY ')}</p>
  } else {
    return null
  }
}

interface FieldRendererRecord {
  id?: number
  getCellValue: <T extends string | object | any[]>(fieldName: string) => T
  getCellValueAsString: (fieldName: string) => string
}
type FieldRendererValue = string | number | any

const FieldRendererValueGetter = (
  field: CardSocketTableField,
  record: FieldRendererRecord,
  value: FieldRendererValue,
) => {
  let cellValue = value
  let cellStringValue = value

  try {
    if (record && record.getCellValue) {
      cellValue = record.getCellValue(field.name)
    }
  } catch (error) {
    cellValue = null
  }

  try {
    if (record && record.getCellValueAsString) {
      cellStringValue = record.getCellValueAsString(field.name)
    }
  } catch (error) {
    cellStringValue = ''
  }
  return { cellValue, cellStringValue }
}

interface FieldRendererProps {
  field: CardSocketTableField
  record: FieldRendererRecord | null
  isName: boolean
  isRevision?: boolean
  value?: FieldRendererValue // value is used to render the field value when record is not available
}

export const FieldRenderer: FC<FieldRendererProps> = ({
  field,
  record,
  isName,
  isRevision,
  value,
}) => {
  const { cellValue, cellStringValue } = FieldRendererValueGetter(
    field,
    record,
    value,
  )
  if (
    field.type === BaserowFieldType.AI ||
    field.type === BaserowFieldType.PASSWORD
  ) {
    return <p className="text-sm text-gray-400">N/A</p>
  }
  if (isNil(cellValue)) return null

  switch (field.type) {
    case BaserowFieldType.NUMBER:
      return (
        <p className="text-gray-500">
          {numberInputFormatter(
            field.number_separator,
            cellValue,
            field.number_prefix,
            field.number_suffix,
            field.number_decimal_places,
          )}
        </p>
      )
    case BaserowFieldType.TEXT:
    case BaserowFieldType.LONG_TEXT:
    case BaserowFieldType.EMAIL:
    case BaserowFieldType.PHONE_NUMBER:
    case BaserowFieldType.AUTONUMBER:
    case BaserowFieldType.COUNT:
    case BaserowFieldType.UUID: {
      return <p>{cellStringValue}</p>
    }
    case BaserowFieldType.CREATED_ON:
    case BaserowFieldType.LAST_MODIFIED:
    case BaserowFieldType.DATE: {
      return (
        <DateValue
          value={cellValue ?? null}
          includeTime={field?.date_include_time}
          showTimeZone={field?.date_show_tzinfo}
          timeZone={
            field?.date_force_timezone ? field.date_force_timezone : null
          }
          timeFormat={field?.date_time_format}
        />
      )
    }
    case BaserowFieldType.URL: {
      let link = cellStringValue
      if (!link?.startsWith('http')) {
        link = `https://${link}`
      }
      return (
        <p
          role="button"
          tabIndex={0}
          className="break-all text-blue-500 hover:cursor-pointer hover:underline"
          onClick={(e) => {
            e.stopPropagation()
            openWindowWithBlockCheck(link, '_blank')
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.stopPropagation()
              openWindowWithBlockCheck(link, '_blank')
            }
          }}
        >
          {cellStringValue}
        </p>
      )
    }
    case BaserowFieldType.FILE: {
      if (!Array.isArray(cellValue) || cellValue?.length === 0) return null
      const fileNodes = cellValue.map((file, index) => (
        <Tooltip title={file?.visible_name} key={index}>
          <div className="flex items-center justify-center rounded-lg bg-gray-200 px-1 hover:cursor-default">
            <p className="p-0.5 text-sm uppercase">
              {file?.visible_name?.split('.').pop()}
            </p>
          </div>
        </Tooltip>
      ))
      return <div className="flex gap-2">{fileNodes}</div>
    }
    case BaserowFieldType.BOOLEAN: {
      if (cellValue) {
        return (
          <div className="grid h-6 w-6 place-items-center rounded-lg bg-indigo-500">
            <CheckIcon
              className="h-5 w-5 text-white"
              data-testid="check-icon"
            />
          </div>
        )
      } else {
        return (
          <div
            className="h-6 w-6 rounded-lg border-2 border-indigo-500"
            data-testid="boolean-no-record-value"
          ></div>
        )
      }
    }
    case BaserowFieldType.FORMULA:
      switch (field.formula_type) {
        case BaserowFormulaType.BUTTON: {
          const link = cellValue?.url
          return (
            <Button
              variant="outlined"
              className="max-w-sm truncate !normal-case"
              onClick={(e) => {
                e.stopPropagation()
                openWindowWithBlockCheck(link, '_blank')
              }}
            >
              {cellValue?.label ?? 'Open'}
            </Button>
          )
        }
        case BaserowFormulaType.ARRAY:
        case BaserowFormulaType.TEXT:
        case BaserowFormulaType.DATE_INTERVAL:
        case BaserowFormulaType.DATE:
          if (field.array_formula_type === 'text') {
            return (
              <p>
                {cellValue
                  ?.map((lookup) => {
                    let parsedLookup
                    try {
                      parsedLookup = JSON.parse(lookup.value)
                    } catch (e) {
                      parsedLookup = lookup.value
                    }
                    if (Array.isArray(parsedLookup)) {
                      return parsedLookup
                        .map((obj) => (obj && obj.value ? obj.value : null))
                        .filter(Boolean)
                    } else {
                      return parsedLookup ? [parsedLookup] : []
                    }
                  })
                  .flat()
                  .join(', ')}
              </p>
            )
          } else if (field.array_formula_type === 'boolean') {
            return <p>{cellValue ? 'True' : 'False'}</p>
          } else if (field.array_formula_type === 'button') {
            return (
              <Button
                className="max-w-sm truncate"
                onClick={(e) => {
                  e.stopPropagation()
                  const link = cellValue[0].value.url
                  openWindowWithBlockCheck(link, '_blank')
                }}
              >
                Open
              </Button>
            )
          } else if (field.array_formula_type === 'date') {
            return <p>{cellValue.map((lookup) => lookup.value).join(', ')}</p>
          } else if (field.array_formula_type) {
            return <p>{cellValue.map((lookup) => lookup.value).join(', ')}</p>
          } else {
            if (field.formula_type === 'date') {
              if (field.date_include_time && cellValue) {
                return <p>{new Date(cellValue).toLocaleString('en-AU')}</p>
              } else if (cellValue) {
                return <p>{new Date(cellValue).toLocaleDateString('en-AU')}</p>
              } else {
                return null
              }
            } else {
              return <p>{cellStringValue}</p>
            }
          }
        case BaserowFormulaType.SINGLE_SELECT: {
          // process with FieldRenderer
          return (
            <div>
              <ColoredChip data={cellValue} isName={isName} />
            </div>
          )
        }
        case BaserowFormulaType.DURATION: {
          return (
            <p>
              {formatDurationValue(
                Number(cellStringValue),
                field.duration_format,
              )}
            </p>
          )
        }
        default:
          return <p>{cellStringValue}</p>
      }
    case BaserowFieldType.SINGLE_SELECT: {
      return (
        <div>
          <ColoredChip data={cellValue} isName={isName} />
        </div>
      )
    }
    case BaserowFieldType.MULTIPLE_SELECT: {
      if (cellValue?.length === 0) return null
      return (
        <div className="flex flex-wrap gap-2">
          {cellValue.map((data) => (
            <ColoredChip
              key={data.id}
              data={{
                value: data.value,
                color: data.color,
              }}
              isName={isName}
            />
          ))}
        </div>
      )
    }
    case BaserowFieldType.LOOKUP: {
      switch (field.array_formula_type) {
        case 'text':
        case 'number':
        case 'rating':
        case 'link':
        case 'char':
        case 'date_interval':
          return (
            <div className="inline-flex gap-2">
              {cellValue.map((lookup) => {
                return lookup?.value ? (
                  <div
                    key={lookup?.id}
                    className="rounded bg-gray-100 p-1 px-1.5"
                  >
                    {lookup?.value}
                  </div>
                ) : null
              })}
            </div>
          )
        case 'multiple_select':
        case 'single_select': {
          let chips = cellValue.flatMap((lookup) => {
            if (!lookup?.value) return []
            // if lookup.value is an array
            if (Array.isArray(lookup?.value)) {
              return lookup.value.map((value) => (
                <ColoredChip
                  key={value?.id}
                  data={{
                    value: value?.value,
                    color: value?.color,
                  }}
                  isName={isName}
                />
              ))
            }
            return [
              <ColoredChip
                key={lookup?.value?.id}
                data={{
                  value: lookup?.value?.value,
                  color: lookup?.value?.color,
                }}
                isName={isName}
              />,
            ]
          })
          chips = chips.filter(Boolean)
          return <div className="inline-flex flex-wrap gap-2">{chips}</div>
        }
        case 'date': {
          return (
            <div className="inline-flex gap-2">
              {cellValue?.map((date) => {
                return date?.value ? (
                  <div
                    key={date?.id}
                    className="truncate rounded bg-gray-100 p-1 px-1.5"
                  >
                    <DateValue
                      value={date?.value}
                      includeTime={field?.date_include_time}
                      timeFormat={field?.date_time_format}
                      showTimeZone={field?.date_show_tzinfo}
                      timeZone={
                        field?.date_force_timezone
                          ? field.date_force_timezone
                          : null
                      }
                    />
                  </div>
                ) : null
              })}
            </div>
          )
        }
        case 'boolean': {
          return (
            <div className="inline-flex gap-2">
              {cellValue?.map((boolean) => {
                if (boolean?.value) {
                  return (
                    <div
                      key={boolean?.id}
                      className="grid h-6 w-6 place-items-center rounded-lg bg-indigo-500"
                    >
                      <CheckIcon className="h-5 w-5 text-white" />
                    </div>
                  )
                } else {
                  return (
                    <div
                      key={boolean?.id}
                      className="h-6 w-6 rounded-lg border-2 border-indigo-500"
                    ></div>
                  )
                }
              })}
            </div>
          )
        }
        case 'duration': {
          return (
            <div className="inline-flex gap-2">
              {cellValue.map((lookup) => {
                return lookup?.value ? (
                  <div
                    key={lookup?.id}
                    className="rounded bg-gray-100 p-1 px-1.5"
                  >
                    {formatDurationValue(lookup?.value, field.duration_format)}
                  </div>
                ) : null
              })}
            </div>
          )
        }
        case 'single_file': {
          if (cellValue?.length === 0) return null
          const fileNodes = cellValue.map((file) => (
            <Tooltip title={file?.visible_name} key={file.id}>
              <div className="flex flex-wrap items-center justify-center rounded-lg bg-gray-200 px-1 hover:cursor-default">
                <p className="truncate p-0.5 text-sm uppercase">
                  {file?.visible_name}
                </p>
              </div>
            </Tooltip>
          ))
          return <div className="flex flex-wrap gap-2">{fileNodes}</div>
        }
        case 'button': {
          if (cellValue?.length === 0) return null
          const buttonNodes = cellValue.map((button) => (
            <Button
              variant="outlined"
              className="!normal-case"
              key={button.id}
              fullWidth={false}
              onClick={(event) => {
                event.stopPropagation()
                openWindowWithBlockCheck(button?.value?.url, '_blank')
              }}
            >
              {button?.value?.label ?? 'Open'}
            </Button>
          ))
          return (
            <div className="flex w-full flex-wrap gap-2 overflow-x-hidden">
              {buttonNodes}
            </div>
          )
        }
        default:
          return <p className="text-sm text-gray-400">N/A</p>
      }
    }
    case BaserowFieldType.LINK_ROW: {
      const LinkedRecord = (
        link: { value: string; id: undefined },
        index: number,
      ) => (
        <div className="mr-auto rounded-md bg-gray-200 px-2 py-1" key={index}>
          <p className="truncate text-sm">{link.value}</p>
        </div>
      )
      if (!cellValue?.length) return null
      return (
        <div className="flex w-full flex-col gap-0.5">
          {isRevision ? (
            cellValue?.map(
              (link: { value: string; id: undefined }, index: number) =>
                LinkedRecord(link, index),
            )
          ) : (
            <>
              {LinkedRecord(cellValue[0], 0)}
              {cellValue?.length > 1 && (
                <p className="text-sm">+{cellValue?.length - 1} more</p>
              )}
            </>
          )}
        </div>
      )
    }
    case BaserowFieldType.MULTIPLE_COLLABORATORS: {
      if (cellValue.length === 0) return null
      return (
        <div className="flex w-full flex-col gap-0.5">
          <div className="mr-auto flex items-center gap-2 rounded-md bg-gray-200 px-2 py-1">
            <NameInitialsAvatar
              name={cellValue[0].name}
              borderWidth="0px"
              bgColor="white"
              size="20px"
              textSize="10px"
            />
            <p className="truncate text-sm">{cellValue[0].name}</p>
          </div>
          {cellValue?.length > 1 && (
            <p className="text-sm">+{cellValue?.length - 1} more</p>
          )}
        </div>
      )
    }
    case BaserowFieldType.RATING: {
      return (
        <Rating
          name="read-only"
          value={Number(cellValue)}
          max={field.max_value}
          readOnly
        />
      )
    }
    case BaserowFieldType.CREATED_BY:
    case BaserowFieldType.LAST_MODIFIED_BY: {
      return (
        <div className="inline-flex w-auto flex-shrink-0 items-center gap-2 truncate rounded-md bg-gray-200 px-2 py-1">
          <NameInitialsAvatar
            name={cellValue?.name ?? 'Unknown User'}
            borderWidth="0px"
            bgColor="white"
            size="20px"
            textSize="10px"
          />
          <p className="truncate text-sm">
            {cellValue?.name ?? 'Unknown User'}
          </p>
        </div>
      )
    }
    case BaserowFieldType.DURATION: {
      return (
        <p>
          {formatDurationValue(Number(cellStringValue), field.duration_format)}
        </p>
      )
    }
    case BaserowFieldType.ROLLUP: {
      const rollupFunction = field?.rollup_function

      if (field?.formula_type === 'boolean') {
        return (
          <p className="capitalize">
            {rollupFunction}:&nbsp;
            <span className="text-gray-400">
              {cellStringValue ? 'true' : 'false'}
            </span>
          </p>
        )
      }
      return (
        <p className="capitalize">
          {rollupFunction}:&nbsp;
          <span className="text-gray-400">{cellStringValue}</span>
        </p>
      )
    }
  }
}

export default FieldRenderer
