import { NumberInputSeparator } from './enums'

const THOUSAND_SEPARATOR_REGEX = /\B(?=(\d{3})+(?!\d))/g

function numberWithSpacesAndComma(x: string) {
  const [integerPart, decimalPart] = x.toString().split('.')
  const formattedInteger = integerPart.replace(THOUSAND_SEPARATOR_REGEX, ' ')
  return `${formattedInteger}${decimalPart ? `,${decimalPart}` : ''}`
}
function numberWithSpacesAndPeriod(x: string) {
  const [integerPart, decimalPart] = x.toString().split('.')
  const formattedInteger = integerPart.replace(THOUSAND_SEPARATOR_REGEX, ' ')
  return `${formattedInteger}${decimalPart ? `.${decimalPart}` : ''}`
}
function numberWithCommasAndPeriod(x: string) {
  const [integerPart, decimalPart] = x.toString().split('.')
  const formattedInteger = integerPart.replace(THOUSAND_SEPARATOR_REGEX, ',')
  return `${formattedInteger}${decimalPart ? `.${decimalPart}` : ''}`
}
function numberWithPeriodsAndComma(x: string) {
  const [integerPart, decimalPart] = x.toString().split('.')
  const formattedInteger = integerPart.replace(THOUSAND_SEPARATOR_REGEX, '.')
  return `${formattedInteger}${decimalPart ? `,${decimalPart}` : ''}`
}

function addPrefixAndSuffix(value: string, prefix: string, suffix: string) {
  return `${prefix ? `${prefix} ` : ''}${value}${suffix ? ` ${suffix}` : ''}`
}

const numberInputFormatter = (
  numberSeparator: string,
  valueToFormat: string | number,
  prefix: string,
  suffix: string,
  decimalPlaces: number,
) => {
  if (
    // 0 is a valid value
    valueToFormat === null ||
    valueToFormat === undefined ||
    valueToFormat === ''
  )
    return ''

  // Ensure the value has the correct number of decimal places
  let formattedValue
  try {
    formattedValue = Number(valueToFormat).toFixed(decimalPlaces)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error formatting number:', error)
    return valueToFormat
  }

  switch (numberSeparator) {
    case NumberInputSeparator.SPACE_COMMA:
      return addPrefixAndSuffix(
        numberWithSpacesAndComma(formattedValue),
        prefix,
        suffix,
      )
    case NumberInputSeparator.SPACE_PERIOD:
      return addPrefixAndSuffix(
        numberWithSpacesAndPeriod(formattedValue),
        prefix,
        suffix,
      )
    case NumberInputSeparator.COMMA_PERIOD:
      return addPrefixAndSuffix(
        numberWithCommasAndPeriod(formattedValue),
        prefix,
        suffix,
      )
    case NumberInputSeparator.PERIOD_COMMA:
      return addPrefixAndSuffix(
        numberWithPeriodsAndComma(formattedValue),
        prefix,
        suffix,
      )
    default:
      return addPrefixAndSuffix(formattedValue, prefix, suffix)
  }
}
export default numberInputFormatter
