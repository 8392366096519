import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import {
  MagnifyingGlassIcon,
  PlusCircleIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'
import {
  AllCommunityModule,
  ColDef,
  ModuleRegistry,
  themeQuartz,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { InfoCircle } from 'iconoir-react'

import {
  getFieldOptionsByViewId,
  getRecordById,
  getRecordsForTableByPage,
  getRecordsForTableByTextSearch,
  getRecordsForViewByPage,
  getRecordsForViewByTextSearch,
  getTableById,
} from 'src/components/HubDash/lib/baserow/baserowApi'
import RecordExpandWrapper from 'src/components/HubDash/RecordExpand/RecordExpandWrapper'
import Button from 'src/components/Library/Button'
import Loading from 'src/components/Library/Loading/Loading'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import useHubDashStore from 'src/lib/stores/hubDashStore'

import type { HubDashCardType } from '../../lib/types'
import { formatPrimaryFieldValue } from '../utils'

interface CellLinkRowRenderProps {
  field: any // not typed yet
  cellValue: any // not typed yet
  setCellValue: Dispatch<SetStateAction<any>> // not typed yet
  baseId: number
  workspaceId: number
  card: HubDashCardType
  disabled: boolean
}

interface LinkedRecordProps {
  linkedTableRecord: any // not typed yet
  linkedRecordIds: string[]
  setCellValue: Dispatch<SetStateAction<any>> // not typed yet
  primaryField: any // not typed yet
}

const recordGridTheme = themeQuartz.withParams({
  accentColor: '',
  browserColorScheme: 'light',
  headerFontSize: 14,
})

const LinkedRecord = ({
  linkedTableRecord,
  linkedRecordIds,
  setCellValue,
  primaryField,
}: LinkedRecordProps) => {
  ModuleRegistry.registerModules([AllCommunityModule])
  const primaryFieldValue =
    linkedTableRecord[primaryField.name] ||
    linkedTableRecord[`field_${primaryField.id}`]
  const displayValue = formatPrimaryFieldValue(primaryField, primaryFieldValue)
  const isLinked = linkedRecordIds?.includes(linkedTableRecord.id)

  return (
    <div
      role={'button'}
      tabIndex={0}
      className={`flex h-full items-center justify-between p-2 ${isLinked ? 'text-indigo-500' : 'text-gray-500'}`}
    >
      <p className="h-fit truncate">
        {displayValue ? displayValue : 'Unnamed Row'}
      </p>
      {isLinked && (
        <button
          onClick={(e) => {
            e.stopPropagation()
            setCellValue((prev) =>
              prev.filter((row) => row.id !== linkedTableRecord.id),
            )
          }}
          className="rounded-full bg-white hover:bg-red-200"
        >
          <XCircleIcon className="h-6 w-6" />
        </button>
      )}
      {!isLinked && (
        <button
          onClick={(e) => {
            e.stopPropagation()
            setCellValue((prev) => [
              ...prev,
              {
                id: linkedTableRecord.id,
                value: displayValue,
              },
            ])
          }}
          className="rounded-full bg-white hover:bg-indigo-200"
        >
          <PlusCircleIcon className="h-6 w-6" />
        </button>
      )}
    </div>
  )
}

// this is slow on a large table, need to implement pagination
export const CellLinkRowRender: FC<CellLinkRowRenderProps> = ({
  field,
  cellValue,
  setCellValue,
  baseId,
  workspaceId,
  card,
  disabled,
}) => {
  const [token] = useHubDashStore((state) => [state.token])
  const [linkSelectOpen, setLinkSelectOpen] = useState(false)
  const [linkSearchValue, setLinkSearchValue] = useState('')
  const [linkedTable, setLinkedTable] = useState({ name: '' })
  const [linkedTableRecords, setLinkedTableRecords] = useState([])
  const [linkedTableRecordsLoading, setLinkedTableRecordsLoading] =
    useState(false)
  const [wrappedRecord, setWrappedRecord] = useState<object>()
  const [wrappedTable, setWrappedTable] = useState({})
  const searchedRef = useRef(false)

  const linkedRecordIds = cellValue?.map((linkRow) => {
    return linkRow.id
  })

  useEffect(() => {
    const fetchLinkedTable = async () => {
      if (field.type === 'link_row' && linkSelectOpen) {
        const linkedTableId = field.link_row_table
        const linkedTable = await getTableById({
          token,
          tableId: linkedTableId,
        })
        setLinkedTable(linkedTable)
      }
    }
    fetchLinkedTable()
  }, [field, linkSelectOpen, token])

  const fetchLinkedTableRecords = useCallback(async () => {
    setLinkedTableRecordsLoading(true)
    try {
      if (field.type === 'link_row' && linkSelectOpen) {
        const linkedTableId = field.link_row_table
        const linkedRowLimitSelectionViewId =
          field.link_row_limit_selection_view_id
        if (linkedRowLimitSelectionViewId) {
          const linkedViewRecordsResponse = await getRecordsForViewByPage({
            token,
            viewId: linkedRowLimitSelectionViewId,
            pageNumber: 1,
          })
          setLinkedTableRecords(linkedViewRecordsResponse.results)
        } else {
          //only fetch first page for now
          const linkedTableRecordsResponse = await getRecordsForTableByPage({
            token,
            tableId: linkedTableId,
            pageNumber: 1,
          })
          setLinkedTableRecords(linkedTableRecordsResponse.results)
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch linked records:', err)
    } finally {
      setLinkedTableRecordsLoading(false)
    }
  }, [field, linkSelectOpen, token])

  useEffect(() => {
    fetchLinkedTableRecords()
  }, [fetchLinkedTableRecords])

  const loadLinkedRecord = async (recordId: number) => {
    const linkedTableId = field.link_row_table
    const recordIdResult = await getRecordById({
      token,
      tableId: linkedTableId,
      recordId,
      workspaceId: workspaceId,
    })
    if (field.link_row_limit_selection_view_id) {
      const { field_options } = await getFieldOptionsByViewId({
        token,
        viewId: field.link_row_limit_selection_view_id,
      })
      const sortedFields = recordIdResult?.table.fields
        .map((item) => {
          const viewItem = field_options[item.id]
          if (viewItem) {
            return {
              ...item,
              viewOrder: viewItem.order,
              viewHidden: viewItem.hidden,
            }
          } else {
            return item
          }
        })
        .sort((a, b) => a.viewOrder - b.viewOrder)
      recordIdResult.table.fields = sortedFields
    }

    const { table, record } = recordIdResult ?? {}
    setWrappedTable(table)
    setWrappedRecord(record)
  }

  const onSearch = async () => {
    setLinkedTableRecordsLoading(true)
    const linkedTableId = field.link_row_table
    const linkedRowLimitSelectionViewId = field.link_row_limit_selection_view_id
    let res = { results: [] }
    if (linkedRowLimitSelectionViewId) {
      res = await getRecordsForViewByTextSearch({
        token,
        viewId: linkedRowLimitSelectionViewId,
        searchValue: linkSearchValue,
      })
    } else {
      res = await getRecordsForTableByTextSearch({
        token,
        tableId: linkedTableId,
        searchValue: linkSearchValue,
      })
    }
    //remember the record is search so when the user clear the search field we can re-init record for them
    searchedRef.current = true
    setLinkedTableRecords(res.results)
    setLinkedTableRecordsLoading(false)
  }

  const truncateText = (text) => {
    const maxLength = 50
    if (text?.length <= maxLength) {
      return text
    }
    return text?.substring(0, maxLength) + '...'
  }

  const colDefs = useMemo<ColDef[]>(
    () => [
      {
        id: 'linked-row',
        sortable: false,
        resizable: false,
        filter: false,
        flex: 2,
        suppressHeaderContextMenu: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        valueGetter: ({ data }) => {
          const nameValue = data[Object.keys(data)[2]]
          return nameValue
        },
        cellRenderer: ({ data }) => {
          return (
            <LinkedRecord
              key={data.id}
              linkedTableRecord={data}
              linkedRecordIds={linkedRecordIds}
              setCellValue={setCellValue}
              primaryField={field.link_row_table_primary_field}
            />
          )
        },
      },
    ],
    [linkedRecordIds, setCellValue, field.link_row_table_primary_field.name],
  )

  const onEnterKeyDown = () => {
    if (linkSearchValue !== '' && !linkedTableRecordsLoading) {
      onSearch()
    }
  }

  useEffect(() => {
    if (linkSearchValue === '' && searchedRef.current) {
      //if the user clear the search field and a searched is done, re-init the record
      fetchLinkedTableRecords()
      searchedRef.current = false
    }
  }, [linkSearchValue])

  return (
    <>
      <div className="flex w-full flex-col gap-2">
        <div className="flex w-full flex-wrap gap-1">
          {cellValue?.map((linkRow) => (
            <div
              key={linkRow.id}
              className="flex h-7 items-center justify-between gap-1 rounded border-2 border-indigo-500 bg-white pl-2 text-indigo-500 hover:bg-indigo-100"
              onClick={() => {
                loadLinkedRecord(linkRow.id)
              }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <div className="truncate">
                <p
                  className="line-clamp-1 truncate"
                  data-testid={`link-row-value-${linkRow?.value}`}
                >
                  {linkRow?.value
                    ? truncateText(linkRow?.value)
                    : 'Unnamed Row'}
                </p>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setCellValue(cellValue.filter((row) => row.id !== linkRow.id))
                }}
                className="mx-0 ml-1 rounded-full bg-white hover:bg-red-200"
              >
                <XCircleIcon
                  className={`h-6 w-6 ${disabled ? 'hidden' : ''}`}
                />
              </button>
            </div>
          ))}
          <button
            className="flex h-7 items-center justify-between gap-1 rounded border-2 border-gray-200 bg-white p-2"
            onClick={() => {
              setLinkSelectOpen(!linkSelectOpen)
            }}
            disabled={disabled}
          >
            {!linkSelectOpen && (
              <>
                <PlusIcon className="h-4 w-4 text-gray-500" />
                <p className="text-gray-500">Edit</p>
              </>
            )}
            {linkSelectOpen && (
              <>
                <XMarkIcon className="h-4 w-4 text-gray-500" />
                <p className="text-gray-500">Close</p>
              </>
            )}
          </button>
        </div>
        {linkSelectOpen && (
          <div className="flex w-full flex-col gap-2 rounded border-2 border-gray-300 bg-white p-2">
            <div className="flex w-full items-center justify-between border-b-2 border-gray-100 pb-2">
              <p>Link Records - {linkedTable?.name}</p>
              <div className="flex items-center gap-2">
                <Tooltip
                  title={
                    'This table is limited to displaying 500 records. To find more records, please use the search feature.'
                  }
                  disableFocusListener
                  arrow
                >
                  <InfoCircle className="h-6 w-6 text-gray-400" />
                </Tooltip>
                <SearchField
                  value={linkSearchValue}
                  onChange={setLinkSearchValue}
                  onEnterKeyDown={onEnterKeyDown}
                />

                <Button
                  className="w-fit"
                  disabled={linkedTableRecordsLoading || !linkSearchValue}
                  onClick={onSearch}
                >
                  <MagnifyingGlassIcon className="h-6 w-6 text-white" />
                </Button>
              </div>
            </div>
            <div className="flex h-96 flex-col gap-2 overflow-scroll px-1">
              <>
                {linkedTableRecordsLoading && <Loading />}

                {!linkedTableRecordsLoading &&
                  linkedTableRecords?.length > 0 && (
                    <AgGridReact
                      rowData={linkedTableRecords}
                      columnDefs={colDefs}
                      theme={recordGridTheme}
                      rowStyle={{ cursor: 'pointer' }}
                      loadThemeGoogleFonts={false}
                      suppressCellFocus={true}
                    />
                  )}
              </>
            </div>
          </div>
        )}
      </div>
      {wrappedRecord && (
        <RecordExpandWrapper
          baseId={baseId}
          wrappedTableName={linkedTable.name}
          wrappedTable={wrappedTable}
          wrappedRecord={wrappedRecord}
          clearWrappedRecord={() => setWrappedRecord(null)}
          card={card}
        />
      )}
    </>
  )
}
