import { useState, type FC } from 'react'

import { Drawer, useMediaQuery } from '@mui/material'

import type { HubDashCardType } from '../lib/types'

import RecordExpand from './RecordExpand'

interface RecordExpandWrapperProps {
  baseId: number
  wrappedTable: any // not typed yet
  wrappedTableName: string
  wrappedRecord: any // not typed yet
  wrappedView?: any // not typed yet
  clearWrappedRecord: () => void
  card?: HubDashCardType
}
const RecordExpandWrapper: FC<RecordExpandWrapperProps> = ({
  baseId,
  wrappedTable,
  wrappedTableName,
  wrappedRecord,
  clearWrappedRecord,
  wrappedView,
  card,
}) => {
  const [commentsOpen, setCommentsOpen] = useState<boolean>(true)
  const isSmallDisplay = useMediaQuery('(max-width:1220px)')
  return (
    <Drawer
      anchor={'right'}
      open={true}
      onClose={clearWrappedRecord}
      PaperProps={{
        style: {
          width: isSmallDisplay ? '100%' : '75%',
          height: '100%',
        },
      }}
    >
      <div className="flex justify-between border-b border-gray-200 px-4 py-2">
        <div className="flex items-center gap-4">
          <div className="">
            <p className="text-3xl">{'Linked Record'}</p>
          </div>
          <div className="flex flex-col border-l border-gray-300 pl-4">
            <p className="text text-gray-500">
              {wrappedTableName || 'Linked Table'}
            </p>
            <p className="text text-gray-500">
              {wrappedTable.viewName || 'All Fields'}
            </p>
          </div>
        </div>
      </div>
      <div
        className="flex h-full w-full flex-col overflow-clip"
        style={{ height: 'calc(100% - 65px)' }}
      >
        <div className="flex h-full w-full">
          <RecordExpand
            cardSocket={{
              records: [wrappedRecord],
              table: wrappedTable,
              base: { id: baseId },
              view: wrappedView || null,
              baserowErrorsState: [],
            }}
            selectedRecord={wrappedRecord}
            clearWrappedRecord={clearWrappedRecord}
            setCommentsOpen={setCommentsOpen}
            commentsOpen={commentsOpen}
            card={card}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default RecordExpandWrapper
