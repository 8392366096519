import { FC, Fragment, useEffect, useRef, useState } from 'react'

import { BellIcon, CheckIcon, BellAlertIcon } from '@heroicons/react/24/outline'
import { MenuItem, Select, Menu } from '@mui/material'
import { captureEvent } from '@sentry/browser'
import axios from 'axios'
import { Mention, MentionsInput } from 'react-mentions'

import { toast } from '@redwoodjs/web/toast'

import { RecordNotificationType } from 'src/components/HubDash/lib/enums'
import Loading from 'src/components/Library/Loading/Loading'
import useHubDashStore from 'src/lib/stores/hubDashStore'
import { useAuth } from 'src/Providers'

import Button from '../../../Library/Button/Button'
import {
  BaserowCommentPayload,
  BaserowRevisionSelectOption,
  BaserowWorkspaceUser,
  type HubDashUser,
  type BaserowWorkspacePermission,
} from '../../lib/types'

import CommentBubble, {
  convertMentionCommentToBrComment,
} from './CommentBubble'
import RevisionBubble from './RevisionBubble'

interface CommentsPanelProps {
  selectedRecord: {
    id: number
    workspaceId: number
    getWorkspaceUsers: () => Promise<BaserowWorkspaceUser[]>
    activityUpdates: any[] // Not typed
    notificationMode: string
  }
  workspacePermission: BaserowWorkspacePermission
  table: any // Not typed
}

const getCommentUser = (
  storeList: HubDashUser[],
  name: string,
): { name: string; avatarUrl: string } => {
  const matchingStoreUser = storeList?.find((user) => user.name === name)

  return {
    name: name ?? 'Unknown User',
    avatarUrl: matchingStoreUser?.avatarUrl ?? '',
  }
}

//when not on SL hub, these users shouldnt be taggable:
const userBanList = [
  'peter@stafflink.com.au',
  'bryn@stafflink.com.au',
  'chris@stafflink.com.au',
  'tech+hub-baserow-service@stafflink.com.au',
  'tech+hub-automations-service@stafflink.com.au',
  'ranie.liwanag@stafflink.com.au',
  'mar@stafflink.com.au',
  'regan@stafflink.com.au',
]

const CommentsPanel: FC<CommentsPanelProps> = ({
  selectedRecord,
  table,
  workspacePermission,
}) => {
  const stafflinkWorkspaces = [183, 187, 356]

  const untaggableUsers = [1, 2, 3, 4, 67, 134]

  const [token, userList] = useHubDashStore((state) => [
    state.token,
    state.userList,
  ])

  const [usersList, setUsersList] = useState<BaserowWorkspaceUser[]>([])
  const [workspaceUsers, setWorkspaceUsers] = useState<BaserowWorkspaceUser[]>(
    [],
  )

  const [itemsFilter, setItemsFilter] = useState('activity')
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id

  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  const [items, setItems] = useState([])
  const [commentsLoading, setCommentsLoading] = useState(true)
  const [enteredComment, setEnteredComment] = useState('')

  const recordComments = items?.filter((item) => item?.type === 'comment')
  const recordRevisions = items?.filter((item) => item?.type === 'revision')

  //Bell button states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [currentBellItemSelection, setCurrentBellItemSelection] = useState(
    selectedRecord?.notificationMode ?? RecordNotificationType.ONLY,
  )

  const commentsEndRef = useRef(null)

  const formatChange = (field, change) => {
    switch (field.type) {
      case 'single_select':
        for (const [_key, value] of Object.entries(field.select_options)) {
          // @ts-expect-error still adding all fields
          if (value.id === change) {
            return {
              // @ts-expect-error still adding all fields
              value: value.value,
              // @ts-expect-error still adding all fields
              color: value.color,
              // @ts-expect-error still adding all fields
              id: value.id,
            }
          }
        }
        break
      case 'multiple_select':
        return change.map((option) => {
          const matchingField =
            (
              Object.values(
                field?.select_options ?? {},
              ) as BaserowRevisionSelectOption[]
            ).find((selectOption) => selectOption?.id === option) ?? null

          if (matchingField) {
            return {
              value: matchingField.value,
              color: matchingField.color,
              id: matchingField.id,
            }
          } else {
            return {
              value: 'Deleted option',
              color: 'gray',
            }
          }
        })
      case 'link_row': {
        return change.map((link) => {
          return {
            value: field?.linked_rows[link]?.value,
            id: field?.linked_rows[link]?.id,
          }
        })
      }
      case 'date': {
        return new Date(change)
      }
      case 'multiple_collaborators': {
        return change?.map((change) => {
          return { id: change?.id, name: change?.name ?? 'Unknown User' }
        })
      }
      case 'boolean':
        return change
    }
  }

  const enrichRevision = (revision) => {
    revision.type = 'revision'
    revision.created_on = revision.timestamp
    revision.changes = []
    for (const [key, value] of Object.entries(revision.fields_metadata)) {
      const field = value as unknown as any // No Types :(

      // Duration history saves format at the point in time of the revision
      // Fetch the current field format so all revisions appear the same
      if (field?.type === 'duration') {
        const matchingField =
          table?.fields?.find((tableField) => tableField?.id === field?.id) ??
          null
        if (matchingField?.duration_format) {
          field.duration_format = matchingField?.duration_format
        }
      }

      // Multiple_select history saves options at time of revision
      // Fetch the current field options so new items don't appear as "deleted"
      if (field?.type === 'multiple_select') {
        const matchingField =
          table?.fields?.find((tableField) => tableField?.id === field?.id) ??
          null
        if (matchingField) {
          field.select_options = matchingField?.select_options
        }
      }

      if (field?.type === 'multiple_collaborators') {
        const matchingField =
          table?.fields?.find((tableField) => tableField?.id === field?.id) ??
          null
        if (matchingField) {
          field.name = matchingField?.name
        }
      }

      revision.changes.push({
        fieldName:
          table.fields.find((field) => `field_${field.id}` === key)?.name ||
          'Deleted Field',
        before: revision.before[key],
        after: revision.after[key],
        field,
        beforeRecord: {
          value: formatChange(value, revision.before[key]),
          getCellValue: (_) => value,
          getCellValueAsString: (_) => revision.before[key],
        },
        afterRecord: {
          value: formatChange(value, revision.after[key]),
          getCellValue: (_) => value,
          getCellValueAsString: (_) => revision.after[key],
        },
      })
    }
    return revision
  }

  const handleCommentSubmit = async () => {
    // Reset value
    setEnteredComment('')

    // Only Create new BR Comment if value has content
    if (enteredComment) {
      const contentArray = convertMentionCommentToBrComment(enteredComment)

      const commentPayload: BaserowCommentPayload = {
        message: {
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: contentArray,
            },
          ],
        },
      }

      try {
        await brApi.post(
          `/row_comments/${table.id}/${selectedRecord.id}/`,
          commentPayload,
        )
        // set notification mode back to mentions only if the user is currently set to mentions only to prevent BR automatically setting first time commenters to all comments
        if (
          (selectedRecord?.notificationMode ?? RecordNotificationType.ONLY) ===
          RecordNotificationType.ONLY
        ) {
          await updateRowCommentNotificationMode(RecordNotificationType.ONLY)
        }
      } catch (error) {
        captureEvent({
          message: 'HubDash: An error occurred creating a comment.',
          level: 'warning',
          extra: {
            tableId: table.id,
            recordId: selectedRecord.id,
            errorCode: error?.response?.data?.error,
          },
        })
      }
    }
  }

  //Bell button functionalities
  const handleBellClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleBellClose = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }
  //update_row_comment_notification_mode
  const updateRowCommentNotificationMode = async (mode: string) => {
    const previousMode = currentBellItemSelection
    setCurrentBellItemSelection(mode)
    const payload = {
      mode:
        mode === RecordNotificationType.ONLY
          ? 'mentions'
          : RecordNotificationType.ALL,
    }
    try {
      await brApi.put(
        `/row_comments/${table.id}/${selectedRecord.id}/notification-mode/`,
        payload,
      )
    } catch (error) {
      toast.error('Failed to update notification preferences')
      captureEvent({
        message: 'HubDash: An error occurred updating notification mode.',
        level: 'warning',
        extra: {
          tableId: table.id,
          recordId: selectedRecord.id,
          errorCode: error?.response?.data?.error,
        },
      })

      setCurrentBellItemSelection(previousMode)
    }
  }
  const handleBellItemClick = async (option: string) => {
    if (option === currentBellItemSelection) return
    await updateRowCommentNotificationMode(option)
  }

  const fetchItems = async () => {
    const allData = []
    const commentsResponse = await brApi.get(
      `/row_comments/${table.id}/${selectedRecord.id}/`,
    )
    const commentsData = commentsResponse.data
    for (const comment of commentsData.results) {
      comment.type = 'comment'
      allData.push(comment)
    }
    const historyResponse = await brApi.get(
      `/database/rows/table/${table.id}/${selectedRecord.id}/history/`,
    )
    const historyData = historyResponse.data

    for (const revision of historyData.results) {
      const enrichedRevision = enrichRevision(revision)
      allData.push(enrichedRevision)
    }
    allData.sort((a, b) => {
      return new Date(a.created_on).getTime() - new Date(b.created_on).getTime()
    })
    setItems(allData)
    setCommentsLoading(false)
  }

  useEffect(() => {
    if (selectedRecord && workspacePermission) {
      if (
        !(
          workspacePermission.createCommentPermission ||
          workspacePermission.rowUpdatePermission
        )
      ) {
        setCommentsLoading(false)
      } else {
        fetchItems()
      }
    }
  }, [selectedRecord.id, workspacePermission])

  useEffect(() => {
    const getBaserowWorkspaceUsers = async () => {
      const users = await selectedRecord.getWorkspaceUsers()
      let filteredUsers = users

      if (!stafflinkWorkspaces.includes(selectedRecord?.workspaceId)) {
        filteredUsers = filteredUsers.filter(
          (user) => !untaggableUsers.includes(user.user_id),
        )
      }

      // these users shouldn't be taggable when not on SL hub
      if (clientId !== 1) {
        filteredUsers = filteredUsers.filter(
          (user) => !userBanList.includes(user.email),
        )
      }
      setUsersList(filteredUsers.sort((a, b) => a.name.localeCompare(b.name)))
      setWorkspaceUsers(users)
    }
    if (workspacePermission?.rowUpdatePermission) {
      getBaserowWorkspaceUsers()
    }
  }, [selectedRecord.id, selectedRecord.workspaceId, workspacePermission])

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'auto' })
    }
  }, [items, itemsFilter])

  useEffect(() => {
    if (selectedRecord?.activityUpdates) {
      const updatedItems = selectedRecord?.activityUpdates.filter(
        (activityItem) => {
          return !items.some((item) => item.id === activityItem.id)
        },
      )
      for (let updatedItem of updatedItems) {
        if (updatedItem.type === 'revision') {
          updatedItem = enrichRevision(updatedItem)
        }
      }
      if (updatedItems.length > 0) {
        setItems([...items, ...updatedItems])
      }
    }
  }, [JSON.stringify(selectedRecord?.activityUpdates), items])

  useEffect(() => {
    setCurrentBellItemSelection(
      selectedRecord?.notificationMode ?? RecordNotificationType.ONLY,
    )
  }, [selectedRecord.notificationMode])

  if (commentsLoading) {
    return (
      <div className="flex h-full w-full flex-col bg-gray-100 p-2">
        <Loading />
      </div>
    )
  }

  //permissions
  const accessToComments: boolean =
    workspacePermission.createCommentPermission ||
    workspacePermission.rowUpdatePermission

  return (
    <div className="flex h-full w-full flex-col">
      <div
        className="flex h-12 justify-between border-b border-gray-200 bg-white"
        data-intercom-target="comments-panel-select-activity-type"
      >
        <Select
          value={itemsFilter}
          size="small"
          onChange={(e) => setItemsFilter(e.target.value)}
          className="w-36"
          sx={{
            '& fieldset': {
              border: 'none',
            },
          }}
          disabled={!accessToComments}
        >
          <MenuItem
            value="activity"
            data-intercom-target="comments-panel-show-all-activity"
          >
            All activity
          </MenuItem>
          <MenuItem
            value="comments"
            data-intercom-target="comments-panel-show-comments"
          >
            Comments
          </MenuItem>
          <MenuItem
            value="revisions"
            data-intercom-target="comments-panel-show-edit-history"
          >
            Edit history
          </MenuItem>
        </Select>

        <div className="px-4 py-2">
          <Button
            fullWidth={false}
            variant="text"
            color={
              currentBellItemSelection === RecordNotificationType.ALL
                ? 'primary'
                : 'secondary'
            }
            className="min-w-[0px] p-1"
            onClick={(e) => handleBellClick(e)}
            aria-label="Notification preferences"
            aria-expanded={open}
            aria-haspopup="menu"
            disabled={!accessToComments}
          >
            {currentBellItemSelection === RecordNotificationType.ALL ? (
              <BellAlertIcon className="h-6 w-6" />
            ) : (
              <BellIcon className="h-6 w-6" />
            )}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              handleBellClose(e)
            }
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              role: 'menu',
              'aria-label': 'Notification preferences',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem
              selected={
                currentBellItemSelection === RecordNotificationType.ONLY
              }
              className="flex flex-col gap-1"
              onClick={() => handleBellItemClick(RecordNotificationType.ONLY)}
            >
              <div className="flex w-full justify-between align-middle">
                <p className="w-full text-black">Only mentions</p>
                {currentBellItemSelection === RecordNotificationType.ONLY && (
                  <CheckIcon className="h-5 w-5 text-black" />
                )}
              </div>
              <p className="w-full text-sm text-gray-500">
                You will only receive notifications when you are mentioned
              </p>
            </MenuItem>
            <MenuItem
              selected={currentBellItemSelection === RecordNotificationType.ALL}
              className="flex flex-col gap-1"
              onClick={() => handleBellItemClick(RecordNotificationType.ALL)}
            >
              <div className="flex w-full justify-between align-middle">
                <p className="w-full text-black">All comments</p>
                {currentBellItemSelection === RecordNotificationType.ALL && (
                  <CheckIcon className="h-5 w-5 text-black" />
                )}
              </div>
              <p className="w-full text-sm text-gray-500">
                You will receive notifications for all comments in this row.
              </p>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="flex flex-col gap-2 overflow-auto px-4 pt-4">
        {!accessToComments ? (
          <p className="w-full self-center py-8 text-center text-gray-500">
            You do not have access to comments for this record.
          </p>
        ) : (
          <>
            {items?.length === 0 && itemsFilter === 'activity' && (
              <p className="w-full self-center py-8 text-center text-gray-500">
                There is no activity for this record yet.
              </p>
            )}
            {recordComments?.length === 0 && itemsFilter === 'comments' && (
              <p className="w-full self-center py-8 text-center text-gray-500">
                There are no comments for this record yet.
              </p>
            )}
            {recordRevisions?.length === 0 && itemsFilter === 'revisions' && (
              <p className="w-full self-center py-8 text-center text-gray-500">
                There is no edit history for this record yet.
              </p>
            )}
          </>
        )}

        {items.map((item, index) => (
          <Fragment key={index}>
            {item.type === 'comment' &&
              (itemsFilter === 'activity' || itemsFilter === 'comments') && (
                <div
                  key={index}
                  ref={index === items.length - 1 ? commentsEndRef : null}
                  className="px-2 pb-4"
                >
                  <CommentBubble
                    commenter={getCommentUser(userList, item?.first_name)}
                    comment={item}
                    currentUserName={currentUser.userData.name}
                    usersList={usersList}
                    brApi={brApi}
                    refetch={fetchItems}
                  />
                </div>
              )}
            {item.type === 'revision' &&
              (itemsFilter === 'activity' || itemsFilter === 'revisions') && (
                <div
                  key={index}
                  ref={index === items.length - 1 ? commentsEndRef : null}
                  className="px-2 pb-4"
                >
                  <RevisionBubble
                    commenter={getCommentUser(userList, item?.user?.name)}
                    revision={item}
                    currentUserName={currentUser.userData.name}
                    usersList={usersList}
                    workspaceUsers={workspaceUsers}
                  />
                </div>
              )}
          </Fragment>
        ))}
      </div>
      {accessToComments && (
        <div
          className="mt-auto flex border-t border-gray-200 px-2 pb-3 pt-3"
          data-intercom-target="hubdash-comments-panel"
          data-testid="hubdash-comments-panel"
        >
          <MentionsInput
            value={enteredComment}
            onChange={(e) => setEnteredComment(e.target.value)}
            forceSuggestionsAboveCursor
            allowSpaceInQuery
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleCommentSubmit()
              }
            }}
            placeholder="Add a comment..."
            className="min-h-8 w-full rounded border border-gray-300 bg-gray-100"
            style={{
              input: {
                paddingLeft: '5px',
                paddingTop: '5px',
              },
              suggestions: {
                list: {
                  borderRadius: '5px',
                  border: '1px solid #9ca3af',
                  padding: '5px',
                },
                item: {
                  padding: '5px',
                  borderBottom: '1px solid #f3f4f6',
                  '&focused': {
                    backgroundColor: '#f3f4f6',
                  },
                  fontSize: '12px',
                },
              },
            }}
          >
            <Mention
              trigger="@"
              data={usersList.map((user) => ({
                id: user?.user_id,
                display: user.name,
              }))}
              displayTransform={(_, display) => `@${display}`}
            />
          </MentionsInput>
        </div>
      )}
    </div>
  )
}

export default CommentsPanel
