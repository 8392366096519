import { ChangeEvent, FC, useEffect, useState } from 'react'

import { TextField } from '@mui/material'
import {
  formatDurationValue,
  MAX_BACKEND_DURATION_VALUE_NUMBER_OF_SECS,
  parseDurationValue,
} from 'api/src/common/baserowImport/baserow/modules/database/utils/duration'
import { useDebounceValue } from 'usehooks-ts'

interface CellDurationRenderProps {
  cellValue: number
  setCellValue: (newValue: number) => void
  field: any // not typed yet
  disabled: boolean
}

export const CellDurationRender: FC<CellDurationRenderProps> = ({
  cellValue,
  setCellValue,
  field,
  disabled,
}) => {
  const [fieldError, setFieldError] = useState<string>('')

  const [transformableValue, setTransformableValue] = useState<string>(
    formatDurationValue(cellValue, field.duration_format),
  )

  const [debounceDuration] = useDebounceValue<string>(transformableValue, 1000)

  useEffect(() => {
    // Convert input to seconds using BR function
    const newRawDuration = parseDurationValue(
      debounceDuration,
      field.duration_format,
    )

    // Format to match duration_format using BR function
    const newFormattedDuration = formatDurationValue(
      newRawDuration,
      field.duration_format,
    )

    // IF input value set and it failed BR validate/parse (+ allow 0 as a valid number)
    if (debounceDuration && !newRawDuration && newRawDuration !== 0) {
      // Show UI error
      setFieldError(`Invalid format (${field.duration_format}).`)
    } else if (
      Math.abs(newRawDuration) >= MAX_BACKEND_DURATION_VALUE_NUMBER_OF_SECS
    ) {
      // Input greater than what BR can handle
      // Show UI error
      setFieldError('Value exceeds duration limit.')
    } else {
      setFieldError('')
      setTransformableValue(newFormattedDuration)

      // Send it to BR
      setCellValue(newRawDuration)
    }
  }, [debounceDuration])

  return (
    <TextField
      value={transformableValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setTransformableValue(event.target.value)
      }}
      size="small"
      placeholder={field.duration_format}
      error={fieldError?.length > 0}
      helperText={fieldError}
      disabled={disabled}
    />
  )
}
