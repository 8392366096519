import type { Dispatch, FC, SetStateAction } from 'react'

import { TextField } from '@mui/material'

import AutoComplete from 'src/components/Library/Autocomplete/Autocomplete'
import useHubDashStore from 'src/lib/stores/hubDashStore'

interface CellMultipleCollaboratorsRenderProps {
  cellValue: any // not typed yet
  setCellValue: Dispatch<SetStateAction<any>> // not typed yet
  disabled: boolean
}

export const CellMultipleCollaboratorsRender: FC<
  CellMultipleCollaboratorsRenderProps
> = ({ cellValue, setCellValue, disabled }) => {
  const userList = useHubDashStore((state) => state.userList)

  const usersList = userList.filter(
    (user) => user.listType === 'User' && user?.baserowUserId,
  )

  const options = usersList?.map((user) => {
    return {
      id: Number(user?.baserowUserId),
      name: user?.name,
    }
  })

  return (
    <AutoComplete
      options={options}
      size="small"
      className="w-full"
      getOptionLabel={(option) => option?.name}
      renderInput={(params) => <TextField {...params} />}
      limitTags={3}
      onChange={(_, newValue) => {
        setCellValue(newValue)
      }}
      value={cellValue}
      disableCloseOnSelect
      multiple
      disabled={disabled}
    />
  )
}
